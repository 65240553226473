<template>
  <section class="data-list-container">

    <div class="mb-5">
      <new-button class="mr-5" @click="openAddNew">Adauga Client de portofoliu nou</new-button>
    </div>

    <vx-card>
      <v-data-table
        :headers="headers"
        :hide-default-footer="true"
        :items="paginatedItems.data"
        @update:sort-by="updateSortField"
        @update:sort-desc="updateSortType"
      >

        <template v-slot:header="{props}">

          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
            >
              <template v-if="header.filterable === true">
                <filter-input :filter-name="header.value" placeholder="Filter..." @filter="updateFilter"/>
              </template>
            </th>
          </tr>
        </template>

        <template v-slot:item.logo="{item}">
          <v-img :src="item.logo" max-height="50px" max-width="50px"/>
        </template>

        <template v-slot:item.actions="{item}">
          <edit-button @click="editItem(item)"></edit-button>
          <delete-button @delete="deleteItem(item)"></delete-button>
        </template>

        <template v-slot:footer>
          <v-pagination
            :length="paginatedItems.last_page"
            :total-visible="5"
            :value="currentPage"
            color="#76bd43"
            @input="changePage"
          ></v-pagination>
        </template>

      </v-data-table>
    </vx-card>


  </section>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'
import EditButton from '@/components/general-form/EditButton'
import DeleteButton from '@/components/general-form/DeleteButton'
import FilterInput from '@/components/general-form/FilterInput'

export default {
  name: 'PortfolioCustomersList',
  components: {FilterInput, DeleteButton, EditButton, NewButton},
  data () {
    return {
      headers: [
        {text: 'ID', value: 'id', width: '65'},
        {text: 'Logo', value: 'logo', width: '100', sortable: false},
        {text: 'Denumire', value: 'name', filterable: true},
        {text: '', value: 'actions', width: '150', sortable: false}
      ],
      sortFiled: [],
      sortIsDesc: []
    }
  },
  computed: {
    paginatedItems () {
      return this.$store.getters['portfolioCustomers/paginatedData']
    },
    currentPage () {
      return this.$store.getters['portfolioCustomers/currentPage']
    },
    sortParam () {
      if (this.sortFiled.length) {
        const sortString = []
        for (let i = 0; i < this.sortFiled.length; i++) {
          sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
        }
        return sortString.join(',')
      }
      return ''
    }
  },
  methods: {
    openAddNew () {
      this.$store.dispatch('sidebar/setCurrentActiveSidebarComponent', {
        component: 'add-portfolio-customers',
        title: 'Adauga un nou Client de portofoliu'
      })
    },
    editItem (item) {
      this.$store.dispatch('portfolioCustomers/editItem', item)
    },
    changePage (newPage) {
      this.$store.dispatch('portfolioCustomers/changePage', newPage)
    },
    updateSortField (field) {
      this.sortFiled = field
    },
    updateSortType (isDesc) {
      this.sortIsDesc = isDesc
    },
    updateFilter (searchValue, filterName) {
      if (searchValue === '') {
        this.$store.dispatch('portfolioCustomers/deleteFilter', filterName)
      } else {
        this.$store.dispatch('portfolioCustomers/addFilter', {filterName, searchValue})
      }
    },
    loadItems () {
      this.$store.dispatch('portfolioCustomers/loadItems')
    },
    deleteItem (item) {
      this.$store.dispatch('portfolioCustomers/deleteItem', item.id)
    }
  },
  watch: {
    sortParam (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch('portfolioCustomers/changeSort', newValue)
      }
    }
  },
  created () {
    this.loadItems()
  }
}
</script>
